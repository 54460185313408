@font-face {
  font-family: 'Lato';
  src: url('./lato/Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Light';
  src: url('./lato/Lato-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Bold';
  src: url('./lato/Lato-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Bold-Italic';
  src: url('./lato/Lato-BoldItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Book Antiqua Italic';
  src: url('./book_antiqua/ANTQUAI.TTF') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url('./icons/icomoon.eot?64xt3c');
  src: url('./icons/icomoon.eot?#iefix64xt3c') format('embedded-opentype'),
    url('./icons/icomoon.woff?64xt3c') format('woff'),
    url('./icons/icomoon.ttf?64xt3c') format('truetype'),
    url('./icons/icomoon.svg?64xt3c#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
