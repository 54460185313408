@font-face {
  font-family: 'Lato';
  src: url(/static/media/Lato-Regular.7f690e50.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Light';
  src: url(/static/media/Lato-Light.5b761f2d.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Bold';
  src: url(/static/media/Lato-Bold.44dfe8cc.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Bold-Italic';
  src: url(/static/media/Lato-BoldItalic.1ba4767e.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Book Antiqua Italic';
  src: url(/static/media/ANTQUAI.23ed0038.TTF) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url(/static/media/icomoon.85ada9ff.eot);
  src: url(/static/media/icomoon.85ada9ff.eot?#iefix64xt3c) format('embedded-opentype'),
    url(/static/media/icomoon.83fe1a33.woff) format('woff'),
    url(/static/media/icomoon.9ca23042.ttf) format('truetype'),
    url(/static/media/icomoon.955a3e14.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

